@import "normalize";

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

$background: #eeeeee;
$white: #fff;
$black: #533f37;
$spacing: 3em;

body {
  background-color: $background;
  font-size: 16px;
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.container {
  max-width: 800px;
  margin: auto;
}

a {
  color: $black;
}

.title {
  text-align: center;
  h2, .links {
    display: inline-block;
  }

  .links {
    font-size: 0.9em;
    margin-left: 2em;
  }
}

.hands {
  text-align: center;
  .hand-select {
    margin: 0 5px;
    display: inline-block;
  }
}

#container {
  margin: auto;
}

svg {
	stroke: $black;
	font-family: "HelveticaNeue-Light",
    "Helvetica Neue Light",
    "Helvetica Neue",
    Helvetica,
    Arial,
    "Lucida Grande",
    sans-serif;
}

#clock-background {
  fill: #fff;
  stroke-width: 3px;
  stroke: $black;
}

.svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 800px;
    padding-bottom: 80%; /* aspect ratio */
    vertical-align: top;
    overflow: hidden;
}

.svg-content-responsive {
    display: inline-block;
}


.clock-face {
  //pointer-events: bounding-box;
  opacity: 0.1;

  .guide {
    opacity: 0.1;
  }

  &:hover {
    $hoverOpacity: 0.70;
    opacity: $hoverOpacity !important;
    .guide {
      opacity: $hoverOpacity
    }
  }

  &.enabled {
    $activeOpacity: 0.90;
    opacity: $activeOpacity !important;
    .guide {
      opacity: $activeOpacity;
    }
  }
}

.arm {
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: $black;

  &.second-arm {
    stroke-width: 3px;
  }

  &.day-arm {}
  &.year-arm {}
  &.life-arm {
    stroke-width: 5px;
  }
}

.hands-cover {
	stroke-width:3;
  fill: #fff;
  stroke: $black;
}

.hand-select {
  &.active-hand {
    font-weight: bold;
  }
}

button.link,
.hand-select > button,
.links > button {
  background:none!important;
  color:inherit;
  border:none;
  padding:0!important;
  font: inherit;
  /*border is optional*/
  border-bottom:1px solid #444;
  cursor: pointer;

  &:focus {outline:0;}
}
